<!-- Localized -->
<template>
  <div class="example-page">
  <div>
    <smooth-picker ref="smoothPicker" :data="data" :change="dataChange" />
    <div class="dateButton">
        <button class="button" :class="{'iosDone': this.getMobileOperatingSystem === 'iOS'}" type="button" @click="confirm">{{ $t('form_builder.done') }}</button>
    </div>
  </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { allMonths } from "@shared/utils/constants";
import SmoothPicker from 'vue-smooth-picker'
import 'vue-smooth-picker/dist/css/style.css'
Vue.use(SmoothPicker)
// import dateTime from './dateTime.vue'

  export default {
    components:{
        // dateTime
    },
    data () {
      const nowYear = (new Date()).getFullYear()
      const nowMonth = (new Date()).getMonth()
      const nowDate = (new Date()).getDate()
      const years = []
      for (let i = 1700; i <= nowYear+10; i++) {
        years.push(i)
      }

      return {
        data: [
          {
            currentIndex: parseInt((nowYear - 1700)),
            flex: 3,
            list: years,
            textAlign: 'center',
            className: 'row-group'
          },
          {
            currentIndex: parseInt(nowMonth),
            flex: 3,
            list: allMonths.map((m) => m.small_label),
            listVal: allMonths.map((m) => m.key),
            textAlign: 'center',
            className: 'row-group'
          },
          {
            currentIndex: parseInt(nowDate - 1),
            flex: 3,
            list: [...Array(30)].map((d, i) => i + 1),
            textAlign: 'center',
            className: 'item-group'
          }
        ]
      }
    },
    props:{
        closeDrawer:{
            type:Function,
        },
        currentDateType: {
            type: String,
            default: () => ''
        },
        getMobileOperatingSystem:{
          type:String,
        }
    },
    methods: {
      isLeapYear (year) {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
      },
      dataChange (gIndex, iIndex) {
        const ciList = this.$refs.smoothPicker.getCurrentIndexList()

        if (gIndex === 0 || gIndex === 1) { // year or month changed
          let currentIndex = 15
          let monthCount = 30

          let month = iIndex + 1 // month
          if (gIndex === 0) { // year
            month = this.data[1].list[ciList[1]]
          }
          switch (month) {
            case 2: {let selectedYear = this.data[0].list[ciList[0]] // month
              if (gIndex === 0) { // year
                selectedYear = this.data[0].list[iIndex]
              }

              let isLeapYear = false
              if (this.isLeapYear(selectedYear)) {
                isLeapYear = true
              }

              monthCount = 28
              currentIndex = 14
              if (isLeapYear) {
                monthCount = 29
                currentIndex = 15
              }
              break}
            case 4:
            case 6:
            case 9:
            case 11:{monthCount = 30
              // currentIndex = 15
              break}
            default:monthCount = 31
              // currentIndex = 15
          }
          const list = [...Array(monthCount)].map((d, i) => i + 1)
          this.$refs.smoothPicker.setGroupData(2, { ...this.data[2], ...{ currentIndex, list }})
        }
      },
      confirm () {
        const ciList = this.$refs.smoothPicker.getCurrentIndexList()
        let year = this.data[0].list[ciList[0]]
        let month = this.data[1].list[ciList[1]]
        let day = this.data[2].list[ciList[2]]
        let monthLen=this.data[1].listVal[ciList[1]]     
        if(month<10){
            month="0"+month;
        }
        if(day<10){
          day="0"+day
        }
        if(monthLen<10){
          monthLen='0'+monthLen
        }
        this.$emit('gdate',day+" "+month+", "+year, this.currentDateType, year+"-"+monthLen+"-"+day)
        this.closeDrawer();
      }
    }
  }
</script>

<style scoped>
  
  .smooth-picker[data-v-a1dc87f8]{
        font-size:1.2em !important;
  }
  .dateButton{
    display: flex;
    justify-content: end;
    color:#0d6ad5;
  }
  .iosDone {
    margin-right: 8% !important;
  }

</style>